// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
import { WepConfig } from '../env-config/wep-config.interface';

export const ENVIRONMENT: WepConfig = {
  // General
  PRODUCTION: window['appGlobalEnv']['production'],
  VERSION: window['appGlobalEnv']['version'],
  ALLOWEDROUTES: window['appGlobalEnv']['allowedroutes'],
  OFFLINE_ERROR: window['appGlobalEnv']['offlineError'],
  TRACKING_PRODUCT_CODE_GUIDES_TIMEOUT: window['appGlobalEnv']['trackingProductCodeGuidesTimeout'],
  INVENTORY_LOAD_MAX_FILE_SIZE: window['appGlobalEnv']['inventoryLoadMaxFileSize'],
  DISPLAYTIME: window['appGlobalEnv']['displaytime'],
  MSGTIME: window['appGlobalEnv']['msgtime'],
  TENANT_ID: window['appGlobalEnv']['tenantId'],

  // Integrations
  API: window['appGlobalEnv']['api'],
  SOCKET_URI: window['appGlobalEnv']['socketUri'],
  SOCKET_WEP_CORE_API_MICROSERVICE_ID: window['appGlobalEnv']['socketWepCoreApiMicroserviceId'],
  APIPLANNING: window['appGlobalEnv']['apiplanning'],
  PACKSTATIONAPI: window['appGlobalEnv']['packstationapi'],
  API_INTEGRATOR: window['appGlobalEnv']['apiIntegrator'],
  APIPRINTER: window['appGlobalEnv']['apiprinter'],
  RF: window['appGlobalEnv']['rf'],

  // wep-client Configs
  CLIENTID: window['appGlobalEnv']['clientid'],
  CLIENTSECRET: window['appGlobalEnv']['clientsecret'],

  // rf Configs
  RFCLIENTID: window['appGlobalEnv']['rfclientid'],
  RFCLIENTSECRET: window['appGlobalEnv']['rfclientsecret'],

  // External Secrets
  googleMapsKey: window['appGlobalEnv']['googleMapsKey'],
  APPINSIGHTS_INSTRUMENTATIONKEY: window['appGlobalEnv']['appinsightsInstrumentationkey']
};
