import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AutoComplete } from 'primeng/autocomplete';
import { Component, EventEmitter, HostListener, Input, Output, OnInit, ViewChild, OnChanges,
  AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { ScfAutocompleteComponent, ScfToastrService } from 'scf-library';
import { isEqual as _isEqual, isEmpty as _isEmpty, isUndefined as _isUndefined } from 'lodash';

import { CONSTANTS } from '../../../constants';
import { KeyCode } from '../../../constants/keycode';
import { LabelService } from '../../../../administration/label-management/label.service';
import { SnapshotUtils } from '../../../../utils/utils';

@Component({
  selector: 'app-autocomplete-wrapper',
  templateUrl: 'autocomplete-wrapper.component.html',
  styleUrls: ['autocomplete-wrapper.component.css']
})
/**
* Creates an autocomplete wrapper for any kind of dropdown list
* @class AutocompleteWrapperComponent
* @param {string} appendTo (Optional) DEFAULT='' - Append autocomplete list to specific place in DOM
* @param {boolean} autoFocus (Optional) DEFAULT=true - Autocomplete get focus
* @param {boolean} disabled (Optional) DEFAULT=false - Wether autocomplete input Initializes as disabled or not
* @param {string} dropdownMode (Optional) DEFAULT='blank' - dropdown's mode
* @param {string} field (Optional) DEFAULT=null - Object's property if items are objects
* @param {string} formControlName (Optional) DEFAULT=null - Form's control input name
* @param {string} formGroup (Optional) DEFAULT=null - Form's group reference object
* @param {boolean} forceSelection (Optional) DEFAULT=true - Clears the input if it does not match of the suggestions
* @param {string} id (Optional) DEFAULT=null - Selection's id used to named local storage key
* @param {string} iconPosition (Optional) DEFAULT=null. ValidOptions=[null,'top']; - Input's icon position. Prefer 'top' in Forms
* @param {any[]} items DEFAULT=[] - List of items to be display
* @param {number} minLength (Optional) DEFAULT=false - Autocomplete's minimum input length before calling search endpoint
* @param {string} placeholder (Optional) DEFAULT=this.lbl.placeholder - Autocomplete input's placeholder
* @param {boolean} required (Optional) DEFAULT=false - Only affects placeholder, adding (this.lbl.requiredPlaceholder) if required
* @param {boolean} snapshot (Optional) DEFAULT=true - Wether the selection should be kept in local storage or reset on revisit
* @return {EventEmitter<any>} itemSelected Emit event when item is selected
*/
export class AutocompleteWrapperComponent implements OnInit, OnChanges, AfterViewChecked {
  @ViewChild('autocomplete', { static: false }) autocomplete: ScfAutocompleteComponent;
  @ViewChild('autocomplete', { static: false }) autocompleteInput: AutoComplete;

  @Input() appearance?: string;
  @Input() appendTo?: string;
  @Input() autoFocus?: boolean;
  @Input() autoHighlight?: boolean;
  @Input() disabled?: boolean;
  @Input() dropdownMode?: string;
  @Input() forceSelection?: boolean;
  @Input() formControlName?: string;
  @Input() formGroup?: UntypedFormGroup;
  @Input() id?: string;
  @Input() minLength?: number;
  @Input() placeholder?: string;
  @Input() required?: boolean;
  @Input() snapshot?: boolean;

  // New Autocomplete options
  @Input() title?: string;
  @Input() items?: any[];
  @Input() field?: string;
  @Input() dropdown?: boolean;
  @Input() subField?: string;
  @Input() orderBySubField?: boolean;

  @Output() public itemSelected = new EventEmitter<any>();
  @Output() public backSpace = new EventEmitter<Event>();
  @Output() public escape = new EventEmitter<Event>();

  public selectedItem: any;
  private isRequired: boolean;
  private itemsList: any[];
  private view: string;

  public lbl = {
    errorTitle: this.labelService.labelText('errorTitle'),
    genericError: this.labelService.labelText('genericErrorMessage'),
    placeholder: this.labelService.labelText('select'),
    requiredPlaceholder: this.labelService.labelText('requiredPlaceholder')
  };
  public msgs = {
    emtpySearchError: this.labelService.labelText('searchEmptyContent')
  };

  constructor(private labelService: LabelService,
    private route: ActivatedRoute,
    private notifier: ScfToastrService,
    private cdref: ChangeDetectorRef) {
      this.selectedItem = null;
      this.itemsList = [];
      this.view = this.route.routeConfig.component.name;
  }

  /**
   * @description Init component
   * @return {void}
   */
  public ngOnInit(): void {
    this.autocompleteInit();
    this.getSelectedItem();
  }

  /**
   * @description After content checked.
   * @return {void}
   */
  public ngAfterViewChecked(): void {
    this.cdref.detectChanges();
  }

  /**
   * @description Detect component's changes
   * @return {void}
   */
  public ngOnChanges(): void {
    this.autocompleteInit();
    this.setInputValue();
  }

  /**
   * @description Initializes autocomplete's options and configuration
   * @return {void}
   */
  private autocompleteInit(): void {
    this.appendTo = _isUndefined(this.appendTo) ? '' : this.appendTo;
    this.autoFocus = _isUndefined(this.autoFocus) ? true : this.autoFocus;
    this.autoHighlight = _isUndefined(this.autoHighlight) ? false : this.autoHighlight;
    this.disabled = _isUndefined(this.disabled) ? false : this.disabled;
    this.dropdown = _isUndefined(this.dropdown) ? true : this.dropdown;
    this.dropdownMode = _isUndefined(this.dropdownMode) ? 'blank' : this.dropdownMode;
    this.field = _isUndefined(this.field) ? null : this.field;
    this.subField = _isUndefined(this.subField) ? null : this.subField;
    this.forceSelection = _isUndefined(this.forceSelection) ? true : this.forceSelection;
    this.isRequired = _isUndefined(this.required) ? false : this.required;
    this.items = _isUndefined(this.items) ? [] : this.items;
    this.minLength = _isUndefined(this.minLength) ? CONSTANTS.MIN_LENGTH : this.minLength;
    this.snapshot = _isUndefined(this.snapshot) ? true : this.snapshot;
    this.appearance = CONSTANTS.OUTLINE_STYLE;
    this.title = _isEmpty(this.title) ? CONSTANTS.EMPTY_STRING : this.title;

    if (_isUndefined(this.placeholder)) {
      if (this.isRequired) {
        this.placeholder = '(' + this.lbl.requiredPlaceholder + ') ' + this.lbl.placeholder;
      } else {
        this.placeholder = this.lbl.placeholder;
      }
    }

    // Set Cached items
    this.itemsList = this.items;

    this.setInputValue();
  }

  /**
   * @description Listens for user's keys for custom functions
   * @param {KeyBoardEvent} KeyBoardEvent
   */
  @HostListener('keyup', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (_isEqual(event.key, KeyCode.Escape)) {
      this.escape.emit(event);
    }

    if (_isEqual(event.key, KeyCode.Backspace)) {
      this.backSpace.emit(event);
      if (_isEmpty(this.selectedItem)) {
        this.resetAutocompleteProperties();
      }
    }
  }

  /**
   * @description Reset location autocomplete
   * @return {void}
   */
  public resetAutocompleteProperties(): void {
    this.selectedItem = '';
    this.autocomplete.clearAutocomplete();
    this.itemSelected.emit(undefined);
  }

  /**
   * @description Emit item selection to the parent component
   * @param {any} item Selected item
   * @return {void}
   */
  public selectItem(item: any): void {
    this.itemSelected.emit(item);
  }

  /**
   * @description Set input's value
   * @returns {void}
   */
  public setInputValue(): void {
    let formControl: AbstractControl;
    formControl = this.formGroup ? this.formGroup.get(this.formControlName) : null;
    this.selectedItem = formControl ? formControl.value : null;
  }

  /**
   * @description Gets currently selected item
   * @return {void}
   */
  private getSelectedItem(): void {
    let item: any = null;
    let cachedItem: any;
    cachedItem = SnapshotUtils.getSavedElement(this.view, this.id);

    if (cachedItem) {
      item = cachedItem.value;
      this.selectedItem = item;
      this.itemSelected.emit(this.selectedItem);
    }
  }
}
