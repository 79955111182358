import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { mergeMap } from 'rxjs/internal/operators';
import { Router } from '@angular/router';
import { ScfToastrService } from 'scf-library';
import * as _ from 'lodash';

import { AuthResponse } from './auth-response';
import { AuthService } from '../shared/authentication/services/auth.service';
import { CONSTANTS } from '../shared/constants';
import { ENVIRONMENT } from '../../environments/environment';
import { Equipment } from '../catalog/equipment/equipment';
import { EquipmentService } from '../catalog/equipment/equipment.service';
import { FormValidator } from '../shared/custom-validators/form.validators';
import { LabelService } from '../administration/label-management/label.service';
import { MobileConfig } from '../shared/constants/mobile-config';
import { PendingWork } from '../process/pending-work/pending-work';
import { PendingWorkService } from '../process/pending-work/pending-work.service';
import { TraslateUtils } from '../utils/traslate.utils';
import { User } from '../administration/user/user';
import { Warehouse } from '../catalog/warehouse/warehouse';
import { WepError } from '../shared/wep-error';

@Component({
  selector: 'app-user-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.css']
})
export class LoginComponent implements OnInit {
  public lbl: any;
  public loginform: UntypedFormGroup;
  public mobile: boolean;
  public msgs: any;
  public newPass: boolean;
  public nickname: string;
  public password: string;
  public selectedEquipmentId: number;
  public selectedWarehouse: Warehouse;
  public showEquipmentOption: boolean;
  public showWarehouseOption: boolean;
  public version: string;
  public warehouseMenu: any[];
  public warehouses: Warehouse[];

  constructor(private authService: AuthService,
    private equipmentService: EquipmentService,
    private formBuilder: UntypedFormBuilder,
    private labelService: LabelService,
    private pendingWorkService: PendingWorkService,
    private router: Router,
    private notifier: ScfToastrService) {
      this.mobile = false;
      this.newPass = false;
      this.nickname = null;
      this.password = null;
      this.selectedWarehouse = null;
      this.showEquipmentOption = false;
      this.showWarehouseOption = false;
      this.version = ENVIRONMENT.VERSION;
      this.warehouseMenu = [];
      this.warehouses = [];
      this.lbl = {
        errorTitle: this.labelService.labelText('errorTitle'),
        infoTitle: this.labelService.labelText('infoTitle'),
        loginButton: this.labelService.labelText('loginButton'),
        loginEquipment: this.labelService.labelText('loginEquipment'),
        loginEquipmentPlaceholder: this.labelService.labelText('loginEquipmentPlaceholder'),
        loginPassword: this.labelService.labelText('loginPassword'),
        loginPasswordPlaceholder: this.labelService.labelText('loginPasswordPlaceholder'),
        loginPoweredBy: this.labelService.labelText('loginPoweredBy'),
        loginUser: this.labelService.labelText('loginUser'),
        loginUserPlaceholder: this.labelService.labelText('loginUserPlaceholder'),
        loginUserRequired: this.labelService.labelText('loginUserRequired'),
        loginWarehouse: this.labelService.labelText('loginWarehouse'),
        subtitle: this.labelService.labelText('viewLoginSubtitle'),
        version: this.labelService.labelText('WEPVersion'),
        title: this.labelService.labelText('viewLoginTitle'),
      };
      this.msgs = {
        activeSessionError: {
          tlt: this.lbl.errorTitle,
          msg: this.labelService.labelText('activeSessionError')
        },
        genericError: {
          tlt: this.lbl.errorTitle,
          msg: this.labelService.labelText('genericErrorMessage')
        },
        loginEquipmentError: {
          tlt: this.lbl.errorTitle,
          msg: this.labelService.labelText('loginEquipmentError')
        },
        loginEquipmentCantBeSelected: {
          tlt: this.lbl.errorTitle,
          msg: this.labelService.labelText('loginEquipmentCantBeSelected')
        },
        loginEquipmentNotAvailableError: {
          tlt: this.lbl.errorTitle,
          msg: this.labelService.labelText('loginEquipmentNotAvailableError')
        },
        loginEquipmentWarehouseError: {
          tlt: this.lbl.errorTitle,
          msg: this.labelService.labelText('loginEquipmentWarehouseError')
        },
        loginNoWarehouseError: {
          tlt: this.lbl.errorTitle,
          msg: this.labelService.labelText('loginNoWarehouseError')
        },
        loginUserPasswordError: {
          tlt: this.lbl.errorTitle,
          msg: this.labelService.labelText('loginUserPasswordError')
        },
        loginServerError: {
          tlt: this.lbl.errorTitle,
          msg: this.labelService.labelText('loginServerError')
        },
        loginUserBlockedError: {
          tlt: this.lbl.errorTitle,
          msg: this.labelService.labelText('loginUserBlockedError')
        },
        pendingWorkMessage: {
          tlt: this.lbl.infoTitle,
          msg: this.labelService.labelText('foundPendingWork')
        }
      };
  }

  public ngOnInit() {
    this.bindLoginForm();
    this.validateLoggedInUser();
    this.mobile = window.screen.width >= MobileConfig.WINDOW_WIDTH ? false : true;
    this.selectedEquipmentId = this.mobile ? null : MobileConfig.DEFAULT_EQUIPMENT;
  }

  /**
   * @description Validates if the user is authenticated and if so,
   * redirects to home
   * @return {void}
   */
  public validateLoggedInUser(): void {
    let isAuthenticated: boolean = this.authService.isAuthenticated();
    if (isAuthenticated) {
      this.router.navigate([CONSTANTS.HOME_ROUTE]);
    }
  }

  /**
   * @description Creates the login form
   * @return {void}
   */
  public bindLoginForm(): void {
    this.loginform = this.formBuilder.group({
      nickname: new UntypedFormControl('', [
        Validators.compose([FormValidator.isValid(true),
        Validators.pattern(CONSTANTS.NICKNAME_PATTERN)])]),
      password: new UntypedFormControl('', [FormValidator.isValid(true)]),
    });
  }

  /**
   * @description If login is correct, redirect the application to home
   * @return {void}
   */
  public loginRedirect(): void {
    this.selectedWarehouse = _.isEmpty(this.selectedWarehouse) ? _.head(this.warehouses) : this.selectedWarehouse;
    let warehouseId: number = this.selectedWarehouse.id;
    let accountId: number = null;

    this.authService.login(this.nickname, this.password, warehouseId, accountId, this.selectedEquipmentId, this.mobile)
      .subscribe(() => {
        this.authService.sendLoggedInStatus(true);
      }, (error: WepError) => {
        this.showLoginError(error);
        this.showWarehouseOption = false;
      }, () => {
        if (this.mobile) {
          this.verifyPendingWork();
        }
        this.router.navigate([CONSTANTS.HOME_ROUTE]);
      });
  }

  /**
   * @description Logs the user in using the information on the form, then sets
   * the token on local storage and either redirects to home or sends an error message
   * @return {void}
   */
  public login(): void {
    this.nickname = (this.loginform.value).nickname;
    this.password = (this.loginform.value).password;
    let warehouseId: number = null;
    let accountId: number = null;
    let equipmentId: number = null;
    this.authService.login(this.nickname, this.password, warehouseId, accountId, equipmentId, this.mobile)
      .pipe(mergeMap(() => {
        let user: User = new User();
        user.nickname = this.nickname;
        return this.authService.getWarehousesInfoByUser(user);
      })).subscribe((response: Warehouse[]) => {
        this.authService.resetWrongUsers();
        this.warehouses = response;
        this.selectedWarehouse = _.head(this.warehouses);
        _.map(this.warehouses, warehouse => {
          this.warehouseMenu.push({label: warehouse.description, value: warehouse});
        });
        // Count how many warehouses there are to decide whether to show the menu or just login
        let warehouseCount = _.size(this.warehouses);
        if (warehouseCount > 1) {
          // If there's more than one warehouse to select from, show the Warehouses Menu
          this.showWarehouseOption = true;
        } else if (warehouseCount === 1) {
          // If there's only one warehouse to choose from, select that one and login
          this.showWarehouseOption = false;
          this.selectedWarehouse = _.head(this.warehouses);
          // If request doesn't come from a mobile equipment, we skip equipment selection and keep desktop equipment.
          if (!this.mobile) {
            this.loginRedirect();
          } else {
            this.loginSelectEquipment();
          }
        } else if (_.isEmpty(this.warehouses)) {
          // If there are no assigned warehouses, show the corresponding error
          this.notifier.errorAlert(this.msgs.loginNoWarehouseError.msg);
        }
      }, (error: WepError) => {
        this.showLoginError(error);
        this.showWarehouseOption = false;
      });
  }

  /**
   * @description Redirects to equipment selection or select default equipment (desktop) if it's not a mobile request.
   * @return {void}
   */
  public loginSelectEquipment(): void {
    if (this.mobile) {
      this.showWarehouseOption = false;
      this.showEquipmentOption = true;
    } else {
      this.loginRedirect();
    }
  }

  /**
   * @description Validates if select component is available. If it is, login user and update equipment's status.
   * @return {void}
   */
  public validateEquipment(): void {
    if (!_.isNull(this.selectedEquipmentId) && !_.isEqual(this.selectedEquipmentId, MobileConfig.DEFAULT_EQUIPMENT)) {
      let equipment = new Equipment();
      this.equipmentService.verifyEquipmentWarehouse(this.selectedEquipmentId, this.selectedWarehouse.id).subscribe(
        (response: Equipment) => {
          equipment = response;
          this.selectedWarehouse = _.isEmpty(this.selectedWarehouse) ? _.head(this.warehouses) : this.selectedWarehouse;
          if (_.isEqual(equipment.warehouseId, this.selectedWarehouse.id)) {
            this.loginRedirect();
          } else {
            this.notifier.errorAlert(this.msgs.loginEquipmentWarehouseError.msg);
          }
        }, (error: WepError) => {
          this.notifier.errorAlert(this.labelService.getWepError(error.message));
      });
    } else {
      if (!_.isNull(this.selectedEquipmentId)) {
        this.notifier.errorAlert(this.msgs.loginEquipmentCantBeSelected.msg);
      }
    }
  }

  /**
   * @description Verifies if is there pending work
   * @return {void}
   */
  private verifyPendingWork(): void {
    this.pendingWorkService.findAnyPendingWork()
      .subscribe((pendingWork: PendingWork) => {
        let type = TraslateUtils.getTraslatedName(CONSTANTS.MOVEMENT_TYPE + pendingWork.type);
        this.notifier.infoAlert(this.msgs.pendingWorkMessage.msg.replace('%task_type%', type)
          .replace('%user%', pendingWork.user.nickname));
      },
      (error: WepError) => {
        if (!_.isEqual(error.message, CONSTANTS.MOVEMENT_HISTORY_CANNOT_BE_FOUND)) {
          this.notifier.infoAlert(this.labelService.getWepError(error.message));
        }
      });
  }

  /**
   * @description Verifies if data in error is instance of AuthErroResponse to verify attempts and
   * show error correctly
   * @param {WepError} error - Error to check
   * @returns {void}
   */
  private showLoginError(error: WepError): void {
    if (!_.isNil(error.data) && new AuthResponse().instanceOf(error.data)) {
      const responseBody: AuthResponse = <AuthResponse>error.data;
      this.authService.addFailedAttemptToUser(this.nickname, responseBody);
      const hasNotLimit = !_.isFinite(responseBody.attemptsRemaining);
      error.message = responseBody.userBlocked || hasNotLimit ? responseBody.message : error.message;
      const errorMessage: string = this.labelService.buildMessageParams(
        this.labelService.getWepError(error.message), [_.toString(responseBody.attemptsRemaining)]
      );
      this.notifier.errorAlert(errorMessage);
    } else {
      this.notifier.errorAlert(this.labelService.getWepError(error.message));
    }
  }
}
