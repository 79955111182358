import { Area } from '../../../catalog/area/area';
import { Footprint } from '../../../catalog/footprint/footprint';
import { FootprintDetail } from '../../../catalog/footprint/footprint-detail';
import { Location } from '../../../catalog/location/location';
import { Lpn } from '../../../lpn/lpn';
import { Message } from './message';
import { NumberUtils } from '../../../utils/utils';
import { Product } from '../../../catalog/product/product';
import { Progression } from '../../../catalog/progression/progression';
import { ShipmentOrder } from './shipment-order';
import { ShipmentOrderSummaryComplement } from '../../../planning/shipment-summary/detail/shipment-order-summary-complement';
import { UOM } from '../../../catalog/uom/uom';

export class ShipmentOrderDetail {
  accountId: number;
  allocationRoute: string;
  caseId: number;
  clientProduct: string;
  comments: string;
  complement: ShipmentOrderSummaryComplement[];
  confirmedSerials: string[];
  currency: number;
  minimumShelfLife: number;
  destinationArea: Area;
  destinationLocation: Location;
  dynamicAttribute1: string;
  dynamicAttribute2: string;
  dynamicAttribute3: string;
  existingQuantity: number;
  expirationDate: Date;
  footprint: Footprint;
  footprintDetail: FootprintDetail;
  footprintShipmentOrderDetail: Footprint;
  hasShort: boolean;
  id: number;
  inventoryDetailId: number;
  inventoryRotation: string;
  isBackorder: boolean;
  isCrossDock: boolean;
  isPartial: boolean;
  isRounded: boolean;
  differencialKey: string;
  lot: string;
  lpn: Lpn;
  message: Message;
  missingQuantity: number;
  orderedQuantity: number;
  overassortmentedQuantity: number;
  overassortmentPercentage: number;
  overassortmentQuantity: number;
  packagedQuantity: number;
  pendingQuantity: number;
  pickedQuantity: number;
  plannedQuantity: number;
  product: Product;
  progression: Progression;
  progressionId: number;
  project: string;
  reference: number;
  salesLineOrder: string;
  serial: string;
  shipmentOrder: ShipmentOrder;
  shipmentOrderId: number;
  shortage: number;
  spareQuantity: number;
  unitCost: number;
  uom: UOM;
  volume: number;
  weight: number;
  taskPickedQuantity: number;
  trackingProductCode: string;

  constructor() {
    this.accountId = null;
    this.allocationRoute = null;
    this.caseId = null;
    this.clientProduct = null;
    this.comments = null;
    this.complement = [];
    this.confirmedSerials = [];
    this.currency = null;
    this.minimumShelfLife = null;
    this.destinationArea = new Area();
    this.destinationLocation = new Location();
    this.dynamicAttribute1 = null;
    this.dynamicAttribute2 = null;
    this.dynamicAttribute3 = null;
    this.existingQuantity = null;
    this.expirationDate = null;
    this.footprint = new Footprint();
    this.footprintDetail = new FootprintDetail();
    this.footprintShipmentOrderDetail = new Footprint();
    this.hasShort = null;
    this.id = null;
    this.inventoryRotation = null;
    this.isBackorder = false;
    this.isCrossDock = false;
    this.isPartial = false;
    this.isRounded = false;
    this.lot = null;
    this.lpn = null;
    this.message = new Message();
    this.missingQuantity = null;
    this.orderedQuantity = null;
    this.packagedQuantity = null;
    this.pendingQuantity = null;
    this.pickedQuantity = null;
    this.plannedQuantity = null;
    this.product = new Product();
    this.progression = new Progression();
    this.progressionId = null;
    this.project = null;
    this.reference = null;
    this.salesLineOrder = null;
    this.serial = null;
    this.shipmentOrder = null;
    this.shipmentOrderId = null;
    this.shortage = null;
    this.spareQuantity = null;
    this.unitCost = null;
    this.uom = null;
    this.volume = null;
    this.weight = null;
  }

  /**
   * @description Gets the pending quantity considering overassortment quantity
   * @param {ShipmentOrderDetail} detail - Detail to evalaute
   * @returns {number} - Pending quantity
   */
  public static getPendingQuantity(detail: ShipmentOrderDetail): number {
    const orderedQuantity: number = NumberUtils.decimalAddition(detail.orderedQuantity, detail.overassortmentQuantity);
    const pickedQuantity: number = NumberUtils.decimalAddition(detail.pickedQuantity, detail.overassortmentedQuantity);
    return NumberUtils.decimalSubtraction(orderedQuantity, pickedQuantity);
  }
}
