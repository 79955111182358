import * as moment from 'moment';
import * as momentTimeZone from 'moment-timezone';

import * as _ from 'lodash';
import { CONSTANTS } from './constants';

const DATE_FORMAT = 'YYYY-MM-DD';
const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const VALID_DATE = -1;

export class FormatDate {
  /**
   * @description Checks if date received has the correct format
   * @param {string | Date} date - Date to validate
   * @param {string} dateFormat - Format to validate, YYYY-MM-DD or YYYY-MM-DDTHH:mm:ss.SSS[Z] formats as default.
   * It depends on the type of date variable
   * @returns {boolean} - True if date has de correct format, false if not
   */
  public static isValidFormat(date: string | Date, dateFormat?: string): boolean {
    const dateToValidate: string = (date instanceof Date) ? date.toISOString() : date;
    if (_.isNil(dateFormat)) {
      dateFormat = (date instanceof Date) ? CONSTANTS.DATE_TIMEZONE : CONSTANTS.DATE_FORMAT;
    }
    return moment(dateToValidate, dateFormat, true).isValid();
  }

  /**
   * @description Checks if date is grather than today
   * @param {string | Date} date - Date to validate
   * @returns {boolean} - True if is after, false if not
   */
  public static isAfterOfToday(date: string | Date): boolean {
    return moment(date, CONSTANTS.DATE_FORMAT).isAfter(moment().startOf('day'));
  }

  /**
   * @description Checks if date is grather than today with format
   * @param {string | Date} date - Date to validate
   * @returns {boolean} - True if is after, false if not
   */
  public static isAfterOfTodayWithFormat(date: string | Date): boolean {
    let todayDate: string = moment().format(CONSTANTS.DATE_FORMAT);
    let isAfterDate: boolean = moment(date, CONSTANTS.DATE_FORMAT).isAfter(moment(todayDate, CONSTANTS.DATE_FORMAT));
    return isAfterDate;
  }

  /**
  * @description Formats param's date to string.
  * @param {Date} date Date to format into string.
  * @return {string} Formatted date.
  */
  public static getStringDate(date: Date): string {
    let stringDate: string;
    stringDate = date.getFullYear() + '-'
      + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
    return stringDate;
  }

  /**
   * @description Validates if the date given matches the format needed
   * @param {date} string - Contains the date to validate
   * @return {boolean} - Return true if it matches otherwise, return false
   */
  public static validateDateFormat(date: string): boolean {
    let regEx = /^\d{4}-\d{2}-\d{2}$/;
    if (date.match(regEx) !== null) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * @description Validates if the date given is valid
   * @param {date} string - Contains the date to validate
   * @return {boolean} - Return true if it's valid and if it doesn't return false
   */
  public static validateDateRange(date: string): boolean {
    let isOverflowDate: number = moment(date, DATE_FORMAT).invalidAt();
    return _.isEqual(isOverflowDate, VALID_DATE);
  }

  /**
   * @description Returns the given time in an MSSQL timestamp
   * @return {string} Timestamp in the database format
   */
  public static getCurrentDateString(): string {
    let now: string = moment().format(DATE_TIME_FORMAT);
    return now;
  }

  /**
   * @description Returns the given time in an MSSQL timestamp
   * @return {string} Timestamp in the database format
   */
  public static parseDateSessionTimezone(date: Date): any {
    let timeZone: string = localStorage.getItem(CONSTANTS.TIME_ZONE);
    return momentTimeZone.tz(date, timeZone).format(DATE_TIME_FORMAT);
  }

  /**
   * @description Returns the given time in an MSSQL timestamp
   * @param {Date | string} date - Date to parse
   * @param {string} format - Output date format
   * @return {string} Date with format specified
   */
  public static parseDateSessionTimezoneWithFormat(date: Date | string, format?: string): string {
    const timeZone: string = localStorage.getItem(CONSTANTS.TIME_ZONE);
    const outputDateFormat: string = (format) ? format : CONSTANTS.DATE_TIMEZONE;
    return momentTimeZone.tz(date, timeZone).format(outputDateFormat);
  }


  /**
   * @description Returns date in the selected format
   * @param {Date} date Selected date to format
   * @return {Date} Formatted Date
   */
   public static getFormattedDate(date: Date): Date {
     let formattedDate: any = moment(date, DATE_FORMAT).format(DATE_FORMAT);
     return formattedDate;
   }

  /**
   * @description Returns the current Date in an MSSQL timestamp
   * @return {Date} - Current date in the database format
   */
  public static getThisMoment(timeZone: string): Date {
    let now: string =  momentTimeZone().tz(timeZone).format(DATE_TIME_FORMAT);
    let date: Date = new Date(now);
    return date;
  }

  /**
   * @description adds quantity of units (e.g 7 days) to a given date
   * @return {Date} - added date
   */
  public static addTimeToDate(date: Date, quantity: number, units: moment.unitOfTime.DurationConstructor): Date {
    let newDate: Date = moment(date).add(quantity, units).toDate();
    return newDate;
  }

  /**
   * @description Returns the given dateTime in a date
   * @param {Date} date DateTime to format
   * @param {string} date DateTime to format
   * @return {string} Date in string
   */
  public static dateToString(date: Date | string): string {
    let now: string = moment(date, DATE_FORMAT).format(DATE_FORMAT);
    return now;
  }

  /**
   * @description Returns Date validity to check if it's actually a Date or Date string
   * @param {Date|string} date Date/String to validate wether it is a valid Date
   * @return {boolean} Is Valid or Not
   */
  public static validateDate(date: Date | string): boolean {
    return moment(date, DATE_FORMAT, true).isValid();
  }

  /**
   * @description Returns the actual date in utc format
   * @return {string}
   */
  public static dateNowUTCFormat(): string {
    let dateUTCFormat = moment.utc().format();
    return dateUTCFormat;
  }
}
